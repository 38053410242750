import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const styles = {
    icon: {
    
    }
}
export default function SpringwoodIcon() {
  return (
    <SvgIcon sx={{ height: 36, width: 232, mr: 2 }}>
      <svg
            viewBox="0 0 232 36"
        
        xmlns="http://www.w3.org/2000/svg"
      >
          <g id="iuQwiW.tif">
              <g>
                  <path d="M30,16.3c0,1.9,0,3.7,0,5.6c0,1.5-0.6,2.8-1.9,3.8c-0.5,0.4-1,0.7-1.5,1.1c-1,0.7-1.9,1.3-2.9,2c-0.8,0.5-1.5,1.1-2.3,1.6
			c-0.7,0.5-1.4,1-2.2,1.5c-0.5,0.3-1,0.6-1.5,0.9c-0.2,0.1-0.4,0.1-0.6,0.2c-0.6,0-1.2,0-1.8-0.1c-0.7-0.1-1.2-0.5-1.8-0.8
			c-0.7-0.5-1.5-0.9-2.3-1.4c-0.9-0.6-1.8-1.1-2.6-1.7c-0.6-0.4-1.3-0.8-1.9-1.2c-0.8-0.5-1.5-1-2.2-1.6c-0.9-0.6-1.4-1.5-1.8-2.5
			c-0.2-0.7-0.3-1.3-0.3-2c0-3.5,0-7,0-10.4c0-0.5,0-1,0.2-1.5c0.1-0.3,0.2-0.7,0.4-1C3.4,8,4,7.6,4.6,7.3c0.8-0.4,1.6-0.7,2.4-1
			c0.9-0.3,1.8-0.7,2.8-1c0.9-0.3,1.8-0.7,2.8-1c0.7-0.3,1.5-0.5,2.2-0.7c0.6-0.2,1.3-0.2,2-0.1c0.9,0.1,1.7,0.4,2.5,0.7
			C21,4.8,22.8,5.4,24.5,6c1.1,0.4,2.2,0.8,3.3,1.3c1,0.4,1.7,1.2,2.1,2.3c0.1,0.4,0.2,0.9,0.2,1.4C30,12.7,30,14.5,30,16.3L30,16.3
			z M3.5,16.4L3.5,16.4c0,1.9,0,3.7,0,5.6c0,0.4,0.1,0.7,0.2,1.1C4,24,4.6,24.7,5.4,25.3c0.9,0.6,1.7,1.2,2.6,1.8
			c1.1,0.8,2.2,1.5,3.3,2.2c0.5,0.4,1.1,0.7,1.7,1c0.3,0.2,0.5,0.3,0.8,0.5c0.3,0.3,0.7,0.5,1.1,0.6c0.5,0.2,1.1,0.5,1.7,0.4
			c0.7-0.1,1.3-0.4,1.9-0.7c0.9-0.5,1.7-1.1,2.5-1.6c0.9-0.6,1.8-1.2,2.6-1.8c1.1-0.8,2.1-1.5,3.2-2.3c0.5-0.4,1-0.8,1.4-1.3
			c0.5-0.8,0.8-1.7,0.8-2.7c0-3.4,0-6.8,0-10.2c0-0.4-0.1-0.9-0.2-1.3c-0.3-0.8-0.7-1.3-1.4-1.6C26.7,8,26,7.7,25.3,7.5
			c-1.6-0.6-3.1-1.2-4.7-1.7c-1-0.4-2-0.7-3-1.1c-0.9-0.3-1.8-0.3-2.7,0c-0.6,0.2-1.2,0.5-1.9,0.7c-0.6,0.2-1.3,0.4-1.9,0.7
			C10.1,6.5,9,6.8,8,7.2c-1,0.3-1.8,0.6-2.6,1c-1.2,0.5-1.8,1.4-1.8,2.7C3.5,12.7,3.5,14.6,3.5,16.4z"/>
                  <path d="M20.6,20.8c0.2-0.2,0.4-0.4,0.7-0.6c0.4-0.4,0.8-0.7,1.1-1.1c0,0,0.1-0.1,0.1-0.2c0-1,0-2,0-3c0-0.1,0-0.3,0-0.4
			c0-0.1,0.1-0.2,0.2-0.2c0.5,0,0.4,0,0.4,0.4c0,1,0,2,0,3c0,0.4-0.1,0.7-0.4,1c-0.6,0.6-1.2,1.3-1.9,1.9l-0.1,0.1
			c-0.3,0.2-0.3,0.4-0.3,0.7c0,0.3,0,0.6,0.1,1c0.1-0.1,0.1-0.1,0.2-0.1c0.9-0.9,1.8-1.8,2.7-2.7c0.2-0.2,0.5-0.5,0.7-0.7
			c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4c0-1.4,0-2.8-0.1-4.1V15c-0.2,0-0.4,0-0.6,0c-0.7,0-1.1-0.5-1.1-1.2
			c0-0.5,0.2-1,0.7-1.3s1.2-0.2,1.7,0.4c0.4,0.5,0.4,1,0.1,1.6c-0.1,0.1-0.1,0.3-0.1,0.4c0.1,0.5,0.1,1,0.1,1.5c0,1.2,0,2.4,0,3.6
			c0,0.1,0,0.2-0.1,0.2c-1.1,1.2-2.3,2.4-3.4,3.6c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.1-0.3,0.2-0.4,0.3l-0.1,0.1c0,1.1,0,2.2,0,3.3
			c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2-0.2,0.3c-0.2,0.1-0.3,0.1-0.5,0.2v-0.1c0-1.9-0.1-3.8-0.1-5.7c0-0.3-0.1-0.5-0.1-0.8
			c0-0.5,0.1-0.9,0.1-1.4c0-1,0.1-2.1,0.1-3.1c0-0.2,0-0.4-0.2-0.6c-0.7-0.6-0.6-1.7,0.2-2.1c0.6-0.3,1.3,0,1.7,0.6
			c0.4,0.7-0.2,1.7-0.8,1.8c-0.1,0-0.1,0.1-0.1,0.1c0,0.9,0,1.8,0,2.6C20.6,20.7,20.6,20.7,20.6,20.8z M20.5,17.2
			c0.3,0,0.6-0.3,0.6-0.6c0-0.3-0.2-0.6-0.6-0.6c-0.3,0-0.5,0.3-0.5,0.6C19.9,16.9,20.1,17.2,20.5,17.2z M23.9,14.2
			c0.3,0,0.5-0.2,0.5-0.5c0-0.2-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.2-0.6,0.6C23.2,14,23.4,14.1,23.9,14.2z"/>
                  <path d="M11.9,23.4c0-0.4,0-0.7,0-1.1c0-0.1,0-0.2,0-0.3c0.1-0.3-0.1-0.4-0.2-0.5c-0.4-0.4-0.8-0.7-1.2-1.1
			c-0.4-0.3-0.7-0.7-1.1-1.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-1.2,0-2.4-0.1-3.6c0-0.1,0-0.2,0.1-0.2s0.3-0.1,0.4-0.1
			c0.1,0,0.2,0,0.2,0.2c0,0.4,0,0.8,0,1.1c0,0.8,0,1.6,0,2.3c0,0.1,0,0.2,0.1,0.3c0.5,0.5,1.1,1.1,1.6,1.6c0,0,0,0,0.1,0
			c0-0.1,0-0.1,0-0.1c0-0.5,0-1,0-1.5c0-0.3,0-0.7,0-1c0-0.2,0-0.3-0.2-0.3c-0.4-0.1-0.7-0.4-0.8-0.8c-0.1-0.5,0.1-1,0.5-1.3
			c0.8-0.6,1.7-0.1,2,0.7c0.2,0.5,0,0.9-0.4,1.2c-0.2,0.2-0.3,0.4-0.3,0.6c0.1,1.3,0.1,2.7,0.1,4c0,1.2,0,2.4,0,3.6c0,1,0,2,0,3
			c0,0.1,0,0.3,0,0.4c-0.2-0.1-0.4-0.2-0.6-0.3c0,0-0.1-0.1-0.1-0.2c0-1.3,0-2.6,0-3.9c0-0.2,0-0.4-0.2-0.5
			c-0.4-0.4-0.8-0.7-1.2-1.2c-0.4-0.4-0.8-0.8-1.2-1.2c-0.5-0.5-1-1-1.5-1.5c-0.2-0.2-0.3-0.4-0.3-0.6c0-1.2,0-2.4,0-3.7
			c0-0.3,0-0.6,0-1c0-0.2,0.1-0.2,0.2-0.2C8.2,15,8.2,15,8.2,15.5c0,1.4,0,2.8,0,4.2c0,0.1,0,0.2,0.1,0.2c0.9,0.9,1.8,1.7,2.7,2.6
			C11.3,22.8,11.5,23,11.9,23.4C11.8,23.4,11.8,23.4,11.9,23.4z M11.4,16.7c0,0.3,0.2,0.5,0.6,0.5c0.3,0,0.5-0.3,0.5-0.6
			c0-0.3-0.3-0.5-0.6-0.5C11.6,16.1,11.4,16.3,11.4,16.7z"/>
                  <path d="M6.2,23.4C6.5,23.6,6.7,23.8,7,24c0.2,0.2,0.4,0.4,0.6,0.5c0.5,0.3,0.8,0.7,1.2,1.1c0.5,0.4,0.9,0.8,1.4,1.2
			c0.1,0.1,0.1,0.1,0.2,0.2h0.1c0.3-0.1,0.4,0,0.4,0.3s0,0.6-0.1,0.9c-0.3-0.2-0.5-0.3-0.6-0.5s-0.2-0.2-0.3-0.3
			c-0.3-0.2-0.7-0.4-1-0.6C8,26,7.1,25.3,6.2,24.5C6,24.3,5.8,24,5.7,23.8c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.3-0.8-0.8-0.7-1.4
			c0-0.4,0.2-0.7,0.4-0.9c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0,0.1-0.1,0.1-0.1c0-2.5,0-4.9-0.1-7.4c0-0.2,0-0.4,0-0.6
			c0-0.1-0.1-0.2-0.1-0.2C4.5,12,4.2,11,4.9,10.3c0.3-0.4,0.8-0.6,1.3-0.4c0.5,0.1,0.8,0.4,1,1c0.1,0.5-0.1,1.4-0.8,1.7
			c0,0-0.1,0.1-0.1,0.2c0,0.6,0,1.2,0,1.8c0,2,0,4,0,6c0,0.2,0,0.3,0.3,0.5c0.5,0.3,0.8,1,0.6,1.5C7,22.8,6.8,23.2,6.2,23.4
			C6.2,23.3,6.2,23.4,6.2,23.4z M5.9,21.4c-0.4,0-0.7,0.3-0.7,0.6c0,0.3,0.3,0.7,0.6,0.7c0.3,0,0.7-0.3,0.7-0.6
			C6.6,21.8,6.3,21.4,5.9,21.4z M6.5,11.1c0-0.3-0.2-0.6-0.6-0.6c-0.3,0-0.7,0.3-0.7,0.6s0.4,0.7,0.7,0.7
			C6.2,11.9,6.5,11.5,6.5,11.1z"/>
                  <path d="M21.7,27.9c0-0.3,0-0.6,0.1-1c0,0,0-0.1,0.1-0.1c0.7-0.3,1.2-0.8,1.7-1.3c0.3-0.2,0.6-0.5,0.9-0.7
			c0.5-0.4,0.9-0.7,1.4-1.1c0.1,0,0.1-0.1,0.2-0.1l0.1-0.1l-0.1-0.1c-0.4-0.2-0.7-0.5-0.8-0.9c-0.2-0.6,0.1-1.4,0.7-1.7
			c0.1,0,0.2,0,0.3-0.1c0-0.1,0-0.2,0-0.3c0-2.2,0-4.4,0-6.6c0-0.4,0-0.7,0-1.1c0-0.1-0.1-0.2-0.1-0.2c-0.5-0.3-0.7-0.8-0.7-1.3
			c0-0.6,0.3-1,0.8-1.2c0.5-0.3,1.2,0,1.5,0.5c0.4,0.6,0.3,1.4-0.1,1.8c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.3,0.1-0.3,0.3
			c0,0.3,0,0.6,0,0.8c0,2.3,0,4.6,0,6.9c0,0.2,0,0.3,0.2,0.3c0.1,0,0.1,0.1,0.2,0.1c0.3,0.4,0.6,0.9,0.4,1.4c-0.1,0.6-0.5,1-1,1.1
			c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.6,0.5-1.2,1.1-1.8,1.6c-0.2,0.2-0.4,0.4-0.6,0.5
			c-0.3,0.3-0.6,0.5-1,0.7c-0.1,0.1-0.1,0.1-0.2,0.2C22.3,27.5,22.1,27.7,21.7,27.9C21.8,27.9,21.8,27.9,21.7,27.9z M26.6,21.5
			c-0.4-0.1-0.7,0.4-0.7,0.7c0,0.3,0.4,0.6,0.7,0.6c0.3,0,0.7-0.3,0.7-0.6C27.2,21.8,26.9,21.5,26.6,21.5z M26.6,10.5h-0.1
			C26.2,10.6,26,11,26,11.3c0,0.2,0.3,0.6,0.6,0.6c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.4-0.4,0.4-0.7C27.2,10.8,26.9,10.5,26.6,10.5z"/>
                  <path d="M14.4,10c0-0.3,0-0.6,0-0.9L14.3,9c-0.3,0-0.5,0-0.8,0.1c-0.2,0-0.2,0-0.2-0.3s0-0.3,0.2-0.3c0.3,0,0.5,0,0.8,0
			c0.1,0,0.2,0,0.1-0.1c0-0.2,0-0.5,0-0.7c-0.2,0-0.5,0-0.7,0.1c-0.3,0-0.3,0-0.3-0.3s0-0.4,0.4-0.3c0.2,0,0.4,0.1,0.7,0.1
			c0,0,0-0.1,0-0.2s0-0.1,0-0.2c-0.1-0.6,0-0.5,0.5-0.5c0.1,0,0.2,0.1,0.2,0.2c0,0.2,0,0.4,0,0.6c0.2,0,0.5,0,0.7,0L16,7.1
			c0-0.2,0-0.5,0-0.7c0,0,0-0.1,0-0.2c0.2,0,0.4,0,0.6,0l0.1,0.1c0,0.3,0,0.6,0,0.9c0.2,0,0.3,0,0.4,0s0.3,0,0.5,0
			c0-0.1,0-0.3,0-0.4c0-0.2,0-0.3,0.2-0.3c0.4,0,0.4-0.1,0.4,0.4c0,0.1,0,0.2,0,0.4c0.2,0,0.5,0,0.7,0c0.3,0,0.4,0.1,0.3,0.4
			c0,0.1-0.1,0.2-0.3,0.2c-0.3,0-0.5,0-0.8-0.1c0,0.3,0,0.6,0,0.9c0.2,0,0.5,0,0.7,0c0.4,0,0.4,0.1,0.4,0.4c0,0.2-0.1,0.2-0.4,0.2
			c-0.2,0-0.4,0-0.6,0c0,0.3,0,0.6,0,0.9c0.2,0,0.5,0,0.7-0.1c0.3,0,0.5,0.2,0.4,0.5c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.5,0-0.7,0
			c-0.1,0-0.2,0-0.2,0.2l0,0c0,0.5-0.1,0.4-0.5,0.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.2-0.1-0.2-0.3-0.2c-0.7,0-1.3,0-2,0
			C15,10.9,15,11,15,11.1c0,0.2-0.1,0.2-0.3,0.2c-0.4,0-0.4,0-0.4-0.4c0-0.1,0-0.3,0-0.4c-0.3,0-0.5,0.1-0.7,0.1s-0.3,0-0.3-0.2
			c0-0.3,0-0.4,0.3-0.4C13.9,9.9,14.1,10,14.4,10z M17.4,7.8c-0.8,0-1.6,0-2.3,0c0,0.8,0,1.6,0,2.3c0.8,0,1.6,0,2.3,0
			C17.4,9.3,17.4,8.5,17.4,7.8z"/>
                  <path d="M14.6,30.4c-0.3-0.2-0.5-0.4-0.7-0.5c0,0,0-0.1,0-0.2c0-1.7,0-3.4,0-5.1c0-3.1,0-6.1,0-9.2c0-0.5,0-1,0-1.5
			c0-0.1,0-0.1-0.1-0.2c-0.6-0.6-1.2-1.2-1.9-1.9c-0.2-0.2-0.2-0.2,0-0.3l0.1-0.1c0.3-0.2,0.2-0.2,0.5,0c0.4,0.4,0.9,0.9,1.3,1.3
			c0.2,0.2,0.4,0.4,0.6,0.6c0.1,0.1,0.1,0.2,0.1,0.4c0,1.4,0,2.8,0,4.3c0,3.7,0,7.3,0,11c0,0.4,0,0.7,0,1.1
			C14.6,30.3,14.6,30.3,14.6,30.4z"/>
                  <path d="M20,11.3c0.1,0.2,0.2,0.4,0.3,0.7c0,0.1,0,0.2-0.1,0.2c-0.2,0.2-0.4,0.5-0.6,0.7c-0.3,0.3-0.6,0.6-0.9,1
			c0,0.1-0.1,0.1-0.1,0.2c0,3,0,6,0,8.9c0,2.2,0,4.3,0,6.5v0.1c0,0.3-0.1,0.5-0.4,0.6c-0.1,0-0.2,0.2-0.4,0.2c0-0.3,0-0.6,0-0.8
			c0-2,0-3.9,0-5.9c0-2.2,0-4.3,0-6.5c0-0.9,0-1.8,0-2.7c0-0.8,0-0.8,0.5-1.4c0.3-0.4,0.7-0.7,1-1.1C19.6,11.7,19.8,11.5,20,11.3z"
                  />
                  <path d="M15.9,24.6c0-2.1,0-4.1,0-6.2c0-0.1,0-0.2-0.2-0.3c-0.4-0.2-0.6-0.5-0.6-0.9c-0.1-0.8,0.4-1.3,1-1.5
			c0.3-0.1,0.9,0.1,1.2,0.5c0.5,0.6,0.3,1.7-0.5,2.1c-0.1,0.1-0.2,0.1-0.2,0.3c0,2.8,0,5.7,0,8.5c0,1.3,0,2.5,0,3.8
			c0,0.3,0,0.3-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.3,0-0.3-0.3c0-1.9,0-3.8,0-5.7C15.9,24.9,15.9,24.8,15.9,24.6L15.9,24.6z
			 M16.3,16.4c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.4,0,0.6-0.2,0.6-0.7C16.9,16.7,16.6,16.4,16.3,16.4z"/>
                  <path d="M10.3,8.5c0.5-0.1,0.9,0.1,1.1,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.4,0.3,0.8,0.3,1.2c0,0.1-0.1,0.2-0.1,0.2
			c-0.2,0.3-0.5,0.5-0.7,0.7c-0.1,0.1-0.3,0.2-0.5,0.2c-0.3,0-0.6,0-0.9,0c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.5-0.4-0.7-0.7
			c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.5,0.1-1,0.5-1.4l0,0C9.4,8.6,9.8,8.3,10.3,8.5z M10.2,9.1c-0.5,0-1,0.4-1,0.9s0.5,1,1,1s1-0.6,1-1
			S10.8,9.1,10.2,9.1z"/>
                  <path d="M21.4,11c0.6,0,1.2,0,1.8-0.1c0.2,0,0.5-0.1,0.7-0.1c0.1,0,0.2,0,0.2,0.2c0.1,0.5,0,0.6-0.5,0.6c-0.7,0-1.4,0.1-2.1,0.1
			c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.4-0.4-0.4-0.6c0-0.2,0-0.4,0-0.6c0-0.5,0-1.1,0-1.6c0-0.2,0.1-0.2,0.2-0.2c0.8,0,1.7,0,2.5,0
			c0.2,0,0.2,0.1,0.2,0.3c0,0.1,0,0.3-0.2,0.3c-0.7,0-1.3,0-2,0c-0.2,0-0.2,0-0.2,0.2C21.3,9.9,21.3,10.5,21.4,11z"/>
                  <path d="M10.1,13.9c0,0.2-0.1,0.4-0.3,0.5s-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-1,0.1-1.5-1.1-1.2-1.8
			c0.2-0.4,0.5-0.8,1.1-0.8c0.3,0,0.6,0.1,0.8,0.3C9.9,13,10.1,13.3,10.1,13.9z M8.9,13.2c-0.3,0-0.6,0.2-0.6,0.5s0.3,0.6,0.6,0.6
			s0.6-0.3,0.6-0.6S9.2,13.2,8.9,13.2z"/>
                  <path d="M21.4,25.7c0-0.3,0.2-0.4,0.3-0.6c0.6-0.7,1.3-1.3,1.9-2c0,0,0,0,0.1-0.1c0.4-0.3,0.7-0.2,0.8,0.2c0,0.1,0,0.2-0.1,0.3
			c-0.3,0.2-0.5,0.5-0.8,0.7c-0.4,0.3-0.8,0.7-1.2,1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.1,0.1-0.2,0.2-0.4,0.3
			C21.5,26,21.4,25.9,21.4,25.7L21.4,25.7z"/>
                  <path d="M16.6,13.4c0,0.5,0,0.9,0,1.4c0,0.3,0,0.3-0.3,0.2h-0.1c-0.3,0-0.3,0-0.3-0.4c0-1.1,0-2.3,0-3.4c0-0.1,0.1-0.2,0.2-0.2
			s0.2,0,0.4,0c0.1,0,0.1,0.1,0.1,0.2C16.6,12,16.6,12.7,16.6,13.4L16.6,13.4z"/>
                  <path d="M9.2,23.6c0.1,0,0.1,0.1,0.1,0.1c0.5,0.5,1,1.1,1.5,1.6c0.1,0.1,0.1,0.2,0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
			c0,0-0.1,0-0.1-0.1c-0.4-0.4-0.8-0.8-1.2-1.2c-0.1-0.1-0.3-0.2-0.4-0.3C8.7,24,8.7,23.8,9,23.7C9,23.7,9.1,23.6,9.2,23.6z"/>
                  <path d="M22.9,10.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.5,0.2-0.7,0.1c-0.1-0.1-0.1-0.5,0-0.7c0.2-0.1,0.4-0.2,0.5,0
			c0.2,0.1,0.3,0.1,0.4,0c0.2-0.2,0.3-0.2,0.6-0.2c0.1,0,0.2,0,0.3,0c0.2,0,0.2,0,0.2,0.2s0,0.3,0,0.5c0,0.1-0.1,0.2-0.2,0.2
			c-0.2,0-0.4,0-0.7-0.1C23.2,10.3,23.1,10.2,22.9,10.1z"/>
              </g>
          </g>
          <g>
              <path d="M39.5,13.9l-0.3,0.1c-1-2.1-2.2-2.8-3.4-2.8c-1.3,0-2,0.7-2,1.8c0,1.6,1.5,2.3,3.1,3c1.7,0.8,3.5,1.7,3.5,4.1
		c0,2.6-2.1,3.7-4.5,3.7c-1.2,0-2.4-0.2-3.6-0.6l-0.3-3.3l0.3-0.1c1.2,2.4,2.5,3.4,4.2,3.4s2.4-1,2.4-2.2c0-1.7-1.4-2.4-2.9-3.2
		c-1.7-0.9-3.5-1.7-3.5-3.9c0-2.4,1.9-3.3,4-3.3c1,0,2,0.2,2.8,0.5L39.5,13.9z"/>
              <path d="M42,27.3c0.3-1,0.4-1.8,0.4-3.4v-6.3c0-1.1-0.4-1.8-0.9-2.2v0l2.5-1V16l0.1,0c0.6-0.9,1.7-1.6,3.1-1.6
		c2.3,0,3.7,1.9,3.7,4.6c0,2.6-1.4,4.8-4.6,4.8c-0.9,0-1.6-0.2-2.2-0.5v0.6c0,1.6,0,2.4,0.4,3.4v0L42,27.3L42,27.3z M49.1,19.3
		c0-2.6-1.1-3.7-2.7-3.7c-1.1,0-1.8,0.5-2.2,1.1V21c0.3,1.6,1.4,2.1,2.3,2.1C48,23.1,49.1,21.7,49.1,19.3z"/>
              <path d="M52.7,23.5c0.3-1,0.4-1.8,0.4-3.4v-2.5c0-1.1-0.4-1.8-0.9-2.2v0l2.5-1v2.2h0.1c0.8-0.8,1.7-2.1,3-2.1l0,2.2l-0.2,0
		c-0.3-0.2-1-0.3-1.4-0.3c-0.3,0-0.9,0.3-1.3,1V20c0,1.6,0,2.4,0.4,3.4v0L52.7,23.5L52.7,23.5z"/>
              <path d="M59.4,23.5c0.3-1,0.4-1.9,0.4-3.4v-2.4c0-1.1-0.4-1.8-0.9-2.2v-0.1l2.6-1V20c0,1.5,0,2.4,0.4,3.4v0L59.4,23.5L59.4,23.5z
		 M59.6,11.7c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C60,12.8,59.6,12.3,59.6,11.7z"/>
              <path d="M64.1,23.5c0.3-1,0.4-1.8,0.4-3.4v-2.5c0-1.1-0.4-1.8-0.9-2.2v0l2.5-1v1.8l0.1,0c0.8-1,1.8-1.7,3.1-1.7
		c1.7,0,2.6,1,2.6,2.7v3c0,1.6,0,2.4,0.4,3.4v0h-2.6v0c0.4-1,0.4-1.9,0.4-3.4v-2.6c0-1-0.5-1.8-1.8-1.8c-0.8,0-1.6,0.3-2.2,1.1V20
		c0,1.6,0,2.4,0.4,3.4v0L64.1,23.5L64.1,23.5z"/>
              <path d="M76.8,20.3c-0.9,0.1-1.2,0.5-1.2,0.9c0,0.5,0.6,0.7,1.8,0.8l1.9,0.1c2,0.1,3.2,0.9,3.2,2.3c-0.1,1.7-1.7,3-4.8,3
		c-2.4,0-4.2-0.8-4.2-2.3c0-0.9,0.7-1.6,1.4-1.8c-0.5-0.3-0.8-0.7-0.8-1.3c0-1.1,0.8-1.7,1.7-1.9c-1-0.5-1.6-1.5-1.6-2.6
		c0-1.7,1.5-3.1,3.4-3.1c0.9,0,1.7,0.3,2.3,0.8l2.5-1.2l0,0v1.7h-0.1l-2-0.1c0.4,0.5,0.6,1.1,0.6,1.8c0,1.7-1.5,3-3.5,3
		C77.4,20.5,77.1,20.4,76.8,20.3z M78.2,26.6c2,0,3.1-0.6,3.1-1.5c0-0.9-0.9-1.2-2.1-1.2l-2.1-0.1c-0.6,0-1.1-0.1-1.5-0.2
		c-0.3,0.2-0.6,0.6-0.6,1.2C74.9,26,76.2,26.6,78.2,26.6z M79.5,17.5c0-1.3-0.5-2.4-1.7-2.4c-1.2,0-1.7,1.1-1.7,2.4s0.5,2.3,1.7,2.3
		C79,19.8,79.5,18.8,79.5,17.5z"/>
              <path d="M92.7,23.7h-0.5l-2.4-6.1l-2.5,6.1h-0.5l-2.3-6.8c-0.4-1.2-0.7-1.7-1.2-2.2v0h3.1v0c-0.4,0.5-0.4,1.1-0.1,2.1l1.3,4
		l1.7-4.1c-0.4-1.1-0.7-1.6-1.1-2v0h3.1v0c-0.3,0.4-0.4,1.2,0,2.1l1.6,4l1.3-3.5c0.4-1.3,0.2-2.1-0.3-2.5v0h2.3v0
		c-0.6,0.6-0.8,1.2-1.2,2.3L92.7,23.7z"/>
              <path d="M96.9,19.1c0-2.5,2.2-4.7,4.7-4.7c2.6,0,4.8,2.2,4.8,4.7c0,2.5-2.1,4.7-4.8,4.7C99,23.8,96.9,21.6,96.9,19.1z M104.5,19.1
		c0-2.1-1-4-2.9-4s-2.9,1.9-2.9,4c0,2.1,1,4,2.9,4C103.5,23.1,104.5,21.2,104.5,19.1z"/>
              <path d="M107.7,19.1c0-2.5,2.2-4.7,4.7-4.7c2.6,0,4.8,2.2,4.8,4.7c0,2.5-2.1,4.7-4.8,4.7C109.8,23.8,107.7,21.6,107.7,19.1z
		 M115.3,19.1c0-2.1-1-4-2.9-4s-2.9,1.9-2.9,4c0,2.1,1,4,2.9,4C114.3,23.1,115.3,21.2,115.3,19.1z"/>
              <path d="M125.4,14.8v-2.1c0-1.1-0.4-1.8-0.9-2.2v0l2.6-1v10.4c0,2.3,0.2,2.7,0.9,3.2v0l-2.5,0.6v-1.8h0c-0.5,1-1.7,1.9-3.2,1.9
		c-2.3,0-3.6-1.8-3.6-4.3c0-2.7,1.7-5.1,4.9-5.1C124.2,14.4,124.9,14.5,125.4,14.8z M123,22.5c1,0,1.8-0.5,2.3-1.3V17
		c-0.3-1.5-1.3-1.9-2.2-1.9c-1.7,0-2.7,1.6-2.7,3.8C120.5,21.1,121.4,22.5,123,22.5z"/>
              <path d="M144.7,22.5c-0.3,0.3-2.4,1.3-4.8,1.3c-4,0-6.6-2.9-6.6-6.6c0-3.7,2.8-6.6,6.9-6.6c1.8,0,3.5,0.5,4.4,0.8l0.4,3.2l-0.3,0.1
		c-1.6-2.4-2.7-3.4-4.7-3.4c-3.3,0-4.6,2.5-4.6,5.9s1.4,5.9,4.5,5.9c2.4,0,3.7-1.7,4.9-4.1l0.3,0.1L144.7,22.5z"/>
              <path d="M146.7,21.9c0-2.1,2.9-3.1,5-3.4v-0.9c0-1.4-0.4-2-1.8-2c-0.9,0-1.8,0.3-2.6,0.8l-0.2-0.2c0.8-1,2.1-1.7,3.6-1.7
		c2.1,0,2.7,1.3,2.7,2.8v4.6c0,0.5,0.2,0.9,0.7,0.9c0.4,0,0.7-0.2,1-0.5l0.2,0.2c-0.3,0.6-0.9,1.4-2,1.4c-1,0-1.5-0.6-1.6-1.3
		c-0.6,0.7-1.6,1.4-2.9,1.4C147.5,23.8,146.7,23,146.7,21.9z M149.7,22.6c0.8,0,1.5-0.4,1.9-0.9v-2.7c-1.6,0.3-3.3,1.1-3.3,2.4
		C148.3,22.2,148.8,22.6,149.7,22.6z"/>
              <path d="M156.4,27.3c0.3-1,0.4-1.8,0.4-3.4v-6.3c0-1.1-0.4-1.8-0.9-2.2v0l2.5-1V16l0.1,0c0.6-0.9,1.7-1.6,3.1-1.6
		c2.3,0,3.7,1.9,3.7,4.6c0,2.6-1.4,4.8-4.6,4.8c-0.9,0-1.6-0.2-2.2-0.5v0.6c0,1.6,0,2.4,0.4,3.4v0L156.4,27.3L156.4,27.3z
		 M163.5,19.3c0-2.6-1.1-3.7-2.7-3.7c-1.1,0-1.8,0.5-2.2,1.1V21c0.3,1.6,1.4,2.1,2.3,2.1C162.4,23.1,163.5,21.7,163.5,19.3z"/>
              <path d="M166.9,23.5c0.3-1,0.4-1.9,0.4-3.4v-2.4c0-1.1-0.4-1.8-0.9-2.2v-0.1l2.6-1V20c0,1.5,0,2.4,0.4,3.4v0L166.9,23.5L166.9,23.5
		z M167.1,11.7c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1C167.5,12.8,167.1,12.3,167.1,11.7z"/>
              <path d="M174.1,23.8c-1.1,0-2.1-0.5-2.1-2.2v-6.2h-1v-0.3l2.6-2.3h0.2v2h2.4v0.7h-2.4v6.2c0,0.8,0.4,1.2,1.2,1.2
		c0.7,0,1.1-0.2,1.2-0.3l0.2,0.2C175.9,23.3,175.1,23.8,174.1,23.8z"/>
              <path d="M177.4,21.9c0-2.1,2.9-3.1,5-3.4v-0.9c0-1.4-0.4-2-1.8-2c-0.9,0-1.8,0.3-2.6,0.8l-0.2-0.2c0.8-1,2.1-1.7,3.6-1.7
		c2.1,0,2.7,1.3,2.7,2.8v4.6c0,0.5,0.2,0.9,0.7,0.9c0.4,0,0.7-0.2,1-0.5l0.2,0.2c-0.3,0.6-0.9,1.4-2,1.4c-1,0-1.5-0.6-1.6-1.3
		c-0.6,0.7-1.6,1.4-2.9,1.4C178.3,23.8,177.4,23,177.4,21.9z M180.5,22.6c0.8,0,1.5-0.4,1.9-0.9v-2.7c-1.6,0.3-3.3,1.1-3.3,2.4
		C179,22.2,179.6,22.6,180.5,22.6z"/>
              <path d="M187.1,23.5c0.3-0.6,0.4-1.2,0.4-2.5v-8.3c0-1.1-0.4-1.7-0.9-2.2v-0.1l2.6-0.9v11.5c0,1.3,0.2,2,0.5,2.6v0L187.1,23.5
		L187.1,23.5z"/>
              <path d="M199.9,25.8c-2.2-1.7-3.6-4.4-3.6-7.7c0-3.3,1.4-6,3.6-7.7l0.3,0.3c-1.4,1.7-2.4,4.3-2.4,7.4s1,5.7,2.4,7.4L199.9,25.8z"/>
              <path d="M202.1,19.4v-5.6c0-1.4-0.1-2-0.7-3v0h3.3v0c-0.5,0.9-0.7,1.6-0.7,3v5.5c0,2.7,1.4,3.7,3.2,3.7c2.5,0,3.4-1.8,3.4-3.9v-5
		c0-1.5-0.2-2.2-1.3-3.3v0h3.1l0.1,0c-1,1.2-1.2,1.8-1.2,3.3v5c0,2.5-1.1,4.6-4.4,4.6C204.3,23.8,202.1,22.5,202.1,19.4z"/>
              <path d="M214.1,23.5c0.6-1,0.7-1.6,0.7-3v-6.7c0-1.4-0.1-2-0.7-3v0h3.3v0c-0.6,1-0.7,1.6-0.7,3v6.7c0,1.4,0.1,2,0.7,3l0,0
		L214.1,23.5L214.1,23.5z M221.8,23.5l-4.9-6.3l3.1-3.9c1-1.3,1.3-2,0.9-2.5v0h3.1v0c-1.5,0.7-2.2,1.4-3,2.5l-2.4,2.9l3.9,5.1
		c0.7,0.9,1.3,1.6,2.1,2.1v0H221.8z"/>
              <path d="M228.1,18.1c0-3.1-1-5.7-2.4-7.4l0.3-0.3c2.2,1.7,3.6,4.4,3.6,7.7c0,3.3-1.4,6-3.6,7.7l-0.3-0.3
		C227.1,23.7,228.1,21.2,228.1,18.1z"/>
          </g>
      </svg>
    </SvgIcon>
  );
}
